<template>
    <div class="step" id="step-van-collapse">
        <van-collapse v-model="activeNames">
            <van-collapse-item title="分步解析" name="1">
                <van-tabs v-model="active" >
                    <van-tab v-for="(item,n) in topicData['分步']" :key="n" :title="'第'+(n+1)+'步'">
                        <div class="target">
                            <div class="title">目标：</div>
                            <p class="p1">
                                <!-- {{topicData['分步'][active] && topicData['分步'][active]['步骤']}} -->
                                <span :id="'mubiao_'+n"></span>
                            </p>
                        </div>

                        <div class="step_step">
                            <div class="title">步骤：</div>
                            <div v-show="arrShow[n]" class="step_step_main">
                                <!-- <div class="step_list" v-for="(item,i) in item['解析']" :key="i" :id="'step_list_'+n+'_'+i"></div> -->
                                <template v-for="(array,num) in item['解析']">
                                    <template v-for="(list_1,num_1) in array">
                                        <span :key="num+num_1+'_str'" v-if="list_1[0]=='str'" class="item_jiexi item">{{list_1[1]}}</span>
                                        <span :key="num+num_1+'_tex'" v-else-if="list_1[0]=='tex'" class="item_jiexi tex_item" :id="'step_list_'+n+'_'+num+'_'+num_1"></span>
                                    </template>
                                </template>
                            </div>
                            <div v-show="!arrShow[n]" class="step_step_box" @click="stepCleck(n)">
                                <van-icon name="eye-o" /> 显示步骤
                            </div>
                        </div>
                        
                        <div class="stepInfo" :key="item['答案']+n">
                            <div class="title">结果：</div>
                            <div class="result">
                                <template v-if="item['答案'] == null">
                                    <div class="hideAndSee" v-if="resultArr[n]">
                                        <van-icon name="eye-o" /> 已隐藏，先想想，再看结果
                                    </div>
                                    <div v-show="!resultArr[n]" :id="'resultJie_'+n"></div>
                                </template>
                                <template v-else>
                                    <div class="result-jie" :id="'resultJie_'+n"></div>
                                </template>
                            </div>
                        </div>

                        <div class="btn_btm_con">
                            <div class="btn_btm_info" @click="checkedResult(n)" v-if="resultArr[n]">校验结果</div>
                            <template v-else>
                                <div class="btn_btm_info" @click="next" v-if="active !== topicData['分步'].length-1">下一步</div>
                            </template>
                            
                            
                        </div>

                    </van-tab>
                    
                </van-tabs>
            </van-collapse-item>
        </van-collapse>
    </div>
</template>

<script>
    var $ = require( "jquery" );
    export default {
        data() {
            return {
                activeNames: [],
                active: 10,
                step: [],
                arrShow: [],
                resultArr: [], // 是否显示下一步

                stepIsYes:[],//判断
            }
        },
        props: {
            topicData: {
                type: Object,
                default: {}
            },
        },
        watch: {
            active(newValue, oldValue) {
                if(this.activeNames[0] == '1'){
                    this.getStep(newValue);
                }
            },
            activeNames(val){
                let newValue = this.active;
                if(val[0] == '1'){
                    this.getStep(newValue);
                    this.$store.commit('setStepShow',true);
                }else{
                    this.$store.commit('setStepShow',false);
                }
            },
            topicIsTrue(val){
                console.log(123231312312,val);
            }
        },
        computed: {
            topicIsTrue() {
                let topicIsTrue = this.$store.state.topicIsTrue;
                console.log(12333,topicIsTrue);
                if(topicIsTrue === false){
                    if(this.$route.name=='special'){
                        this.activeNames = ['1'];
                    }
                    this.active=0;
                } else{
                    this.activeNames = [];
                    this.active=0;
                }
                return this.$store.state.topicIsTrue;
            }
        },
        methods: {
            getStep(newValue){
                if(this.stepIsYes[newValue]){
                    return false;
                }
                this.$nextTick(()=>{
                    let data = this.step[newValue]['解析'];
                    try {
                        data.map((e,n)=>{
                            e.map((el,i)=>{
                                if(el[0]=='tex'){
                                    let id='step_list_'+newValue+'_'+n+'_'+i;
                                    console.log(id);
                                    let dom = document.getElementById(id);
                                    this.$kx.render(el[1], dom, {
                                        displayMode: true,
                                    });
                                }
                            })
                        }) 

                        let dom_mobiao = document.getElementById('mubiao_'+newValue);
                        this.$kx.render(this.step[newValue]['步骤'], dom_mobiao, {
                            displayMode: true,
                        });

                        let dom = document.getElementById('resultJie_'+newValue);
                        let jie = this.step[newValue]['解'];
                        this.$kx.render(jie, dom, {
                            displayMode: true,
                        });
                        this.$nextTick(()=>{
                            let mcloseArr = $('#resultJie_'+newValue).find('.cjk_fallback');
                            $.each(mcloseArr, function (index) {
                                let item = mcloseArr[index];
                                if ($(item).html() == "冇") {
                                    $(item).html("<input type='text' class='inputClass'>")
                                }
                            });
                        })
                    } catch (error) {
                        
                    }

                    this.stepIsYes[newValue]=true;
                })
                
            },
            // 切换 下一题 后 初始化
            init(){
                this.active=0;
                this.resultArr=[];
                this.activeNames=[];
                this.stepIsYes=[];
                this.showStep();
            },
            showStep() {
                let topicData = this.topicData;
                if(Object.keys(topicData).length>0){
                    let step = topicData['分步'];
                    this.step = step;
                    step.map((e,n)=>{
                        this.arrShow[n]=false;

                        this.resultArr.push(true);
                    });
                    
                    if(this.topicIsTrue === false){
                        this.active=0;
                    }
                    
                }
            },
            stepCleck(e){
                this.$set(this.arrShow,e,true);
                console.log('this.arrShow', this.arrShow); 
            },
            next(){
                this.active++;
            },
            // 校验结果
            checkedResult(n){
                this.$set(this.resultArr,n,false)
                if(this.step[+this.active]["答案"] == null){
                    return
                }
                let dataArr= [],
                inputClass= $('#resultJie_'+n).find('.inputClass');
                inputClass.map((i,e)=>{
                    dataArr.push(e.value.toString());
                });
                this.step[+this.active]["答案"].map((e,i)=>{
                    inputClass.map((n,el)=>{
                        if(el.value != e && i == n){
                            if(el.value===''){
                                el.value="?"
                            }
                            $(el).before('<span class="jie-err">'+el.value+'</span><span class="jie-succ">'+e+'</span>')
                        }
                        else if(el.value == e && i == n){
                            $(el).before('<span class="jie-succ">'+e+'</span>');
                        }
                    });
                    inputClass.hide();
                    return e.toString()
                });
            }
        },
    }
</script>

<style lang="less" scoped>
    .step{
        &.light{

            /deep/.van-collapse{
                .van-cell{
                    animation:mymove 1s infinite;
                    @keyframes mymove
                    {
                        from {background-color:#fff;border:1px solid #fff;}
                        to {background-color:#FEF8E5;border:1px solid #F9C200;}
                    }
                }
            }
            
        }
        .hideAndSee{
            padding-top: 10px;
            color: #B9BFD3;
            .van-icon-eye-o{
                position: relative;
                top: 2px;
            }
        }
        .title{
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #9498AA;
            line-height: 19px;
        }
        .target{
            color: #2A3155;
            padding-top: 12px;
            .p1{
                padding: 8px 0;
            }
        }
        .item_jiexi{
            display: inline-block;
        }
        .step_step{
            // margin-top: 14px;
            &_box{
                margin-top: 8px;
                height: 40px;
                border-radius: 2px;
                border: 1px solid #B9BFD3;
                text-align: center;
                line-height: 40px;
                i{
                    vertical-align: middle;
                }
            }
            &_main{
                text-align: center;
                overflow: auto;
                padding: 15px 10px;
                font-size: 13px;
                /deep/.katex{
                    font-size: 13px;
                }
            }
        }
        .stepInfo{
            padding: 16px 0;
            border-bottom: 1px solid #E6E8EF;
        }
        .result{
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #2A3155;
            line-height: 19px;
            .title{
                padding-bottom: 14px;
            }
            &-input{
                margin-left: 12px;
                width: 132px;
                height: 32px;
                background: #FFFFFF;
                border-radius: 2px;
                border: 1px solid #B9BFD3;
            }
        }
        .btn_btm_con{
            padding: 16px;
            text-align: center;
            margin-bottom: 4px;
        }
        .btn_btm_info{
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #2A3155;
            line-height: 40px;
            width: 193px;
            height: 40px;
            background: #EFF2F7;
            display: inline-block;
        }
        
    }
</style>